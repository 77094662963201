var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "font-weight-regular" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [_c("div", [_vm._v("Référent / Fonction digitale")])]
          ),
        ],
        1
      ),
      _vm.editable
        ? _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-4 btn",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.addReferent },
                },
                [
                  _c("div", { staticClass: "capitalize" }, [
                    _vm._v(" ajouter un référent "),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("v-data-table", {
            attrs: {
              locale: "fr-FR",
              headers: _vm.headers,
              items: _vm.filteredEntries,
              "no-data-text": "aucun référent",
              "disable-pagination": "",
              "hide-default-footer": "",
              loading: _vm.loading,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: _vm.getItemActions(),
                  fn: function ({ item }) {
                    return [
                      _vm.editable
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex flex-grow-0 justify-center",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(item)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil ")]
                              ),
                              _vm.editable
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onShowDeleteDialog(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-close-thick ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }