import * as statusMapper from "@/service/error_request_mapper.js";

/**
 * Service d'accès à l'API Services
 */
export default class ServicesService {
  constructor(apis) {
    this.apis = apis;
    this.api = this.apis.getServicesApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////

  /**
   * Retourne la liste des services trié par ordre alphabétique
   */
  async getAllServices() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Retourne un service identifié par son id
   */
  async getServiceById(serviceId) {
    return this.api.getById(serviceId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  /**
   * Ajouter un service
   */
  async create(service) {
    return this.api.create(service).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(service.name);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  /**
   * Mettre à jour du service
   */
  async update(service) {
    return this.api.update(service).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  /**
   * Supprimer le service
   */
  async delete(serviceId) {
    return this.api.delete(serviceId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(serviceId);
      converter.convert(error);
    });
  }
} // END OF CLASS
