var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "font-weight-regular" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [_c("div", [_vm._v("Détail de l'établissement")])]
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "mb-5 mt-1",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { justify: "center", "align-self": "start" } },
                [
                  _c("div", { staticClass: "capitalize font-weight-regular" }, [
                    _vm._v("nom digital"),
                  ]),
                ]
              ),
              _c("v-col", { attrs: { "align-self": "start" } }, [
                _c("div", [_vm._v(_vm._s(_vm.digitalName))]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "mb-5 mt-1",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { justify: "center", "align-self": "start" } },
                [
                  _c("div", { staticClass: "capitalize font-weight-regular" }, [
                    _vm._v("métier"),
                  ]),
                ]
              ),
              _c("v-col", { attrs: { "align-self": "start" } }, [
                _c("div", [_vm._v(_vm._s(_vm.metier))]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "mb-5 mt-1",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { justify: "center", "align-self": "start" } },
                [
                  _c("div", { staticClass: "capitalize font-weight-regular" }, [
                    _vm._v("activités"),
                  ]),
                ]
              ),
              _vm.activities.length > 0
                ? _c(
                    "v-col",
                    { attrs: { "align-self": "start" } },
                    _vm._l(_vm.activities, function (activity, index) {
                      return _c(
                        "div",
                        { key: "A" + index, staticClass: "text-uppercase" },
                        [_vm._v(" " + _vm._s(activity.label) + " ")]
                      )
                    }),
                    0
                  )
                : _c("v-col", { attrs: { "align-self": "start" } }, [
                    _c("div", [_vm._v("-")]),
                  ]),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "mb-5 mt-1",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { justify: "center", "align-self": "start" } },
                [
                  _c("div", { staticClass: "capitalize font-weight-regular" }, [
                    _vm._v("statut"),
                  ]),
                ]
              ),
              _c("v-col", { attrs: { "align-self": "start" } }, [
                _c("div", [_vm._v(_vm._s(_vm.status))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }