<template>
  <!-- l'affichage de la table -->
  <v-card flat outlined class="my-4">
    <v-card-title class="font-weight-regular">
      <v-row no-gutters justify="space-between">
        <div>Référent / Fonction digitale</div>
      </v-row>
    </v-card-title>

    <v-card-text v-if="editable">
      <v-btn 
        class="px-4 btn" 
        outlined 
        color="primary" 
        @click="addReferent" 
        :disabled="loading"
      >
        <div class="capitalize">
          ajouter un référent
        </div>
      </v-btn>
    </v-card-text>

    <v-container fluid>
      <v-data-table
        locale="fr-FR"
        :headers="headers"
        :items="filteredEntries"
        no-data-text="aucun référent"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:[getItemActions()]="{ item }">
          <div class="d-flex flex-grow-0 justify-center" v-if="editable">
            <v-icon small class="mr-2" color="primary" @click="edit(item)">
              mdi-pencil
            </v-icon>

            <v-icon
              small
              class="mr-2"
              color="primary"
              @click="onShowDeleteDialog(item)"
              v-if="editable"
            >
              mdi-close-thick
            </v-icon>
          </div>
        </template>
      </v-data-table></v-container
    >
  </v-card>
</template>

<script>
export default {
  name: "ReferentsPanel",
  props: {
    /**Les entrées de la table : SERVICE + FONCTION + référent. */
    entries: {
      type: Array,
      default: () => [],
    },

    /**Indicateur de chargement des données. */
    loading: {
      type: Boolean,
      default: false,
    },

    /**La liste des services à afficher. Les autres seront écartés. */
    services: {
      type: Array,
      default: () => [],
    },

    /**Est-ce que l'édition des référents est possible? */
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getItemActions() {
      return `item.actions`;
    },
    /**L'utilisateur veut ajouter un référent fonction digitale */
    addReferent() {
      this.$emit("addReferentEvent");
    },
    onShowDeleteDialog(item) {
      this.$emit("removeReferentEvent", item.functionId, item.referentUuid);
    },
    edit(item) {
      this.$emit("editReferentEvent", item.functionId, item.referentUuid);
    },
  },
  computed: {
    filteredEntries() {
      let list = [...this.entries];
      //on classe les résultats par le couple 'SERVICE/FONCTION'
      list.sort(function (a, b) {
        let labela = `${a.service}/${a.function}`;
        let labelb = `${b.service}/${b.function}`;

        return labela.localeCompare(labelb);
      });

      if (this.services.length === 0) {
        return list;
      } else {
        return list.filter((e) => this.services.includes(e.service));
      }
    },
    headers() {
      let columns = [
        {
          text: "Service",
          align: "start",
          sortable: true,
          value: "service",
        },
        {
          text: "Fonction digitale",
          align: "start",
          sortable: true,
          value: "function",
        },
        {
          text: "Référent",
          align: "start",
          sortable: true,
          value: "referent",
        },
      ];

      if (this.editable) {
        columns.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
        });
      }

      return columns;
    },
  },
  mounted() {},
};
</script>

<style>
</style>