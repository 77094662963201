import * as statusMapper from "@/service/error_request_mapper.js";

/**
 * Service d'accès à l'API  des fonctions
 */
export class FonctionService {
  constructor(api) {
    this.api = api;
  }

  /////////////////////////////// GET /////////////////////////////////////////

  /**
   * Retourne la liste des fonctions
   */
  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Retourne la liste des fonctions
   */
  async getFunctions() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Retourne une fonction identifié par son id
   */
  async getById(functionId) {
    return this.api.getById(functionId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   * Retourne une fonction identifié par son id
   */
  async getFunctionById(functionId) {
    return this.api.getById(functionId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   * Retourne la liste des rubriques (spécifiques ET globales) associées à la fonction
   * triée dans l'ordre alphabétique
   */
  async getAllRubricsForFunction(functionId) {
    return this.api
      .getAllRubricsOfFunction(functionId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Retourne la liste des rubriques spécifiques associées à la fonction
   * triée dans l'ordre alphabétique
   */
   async getSpecificRubricsForFunction(functionId) {
    return this.api
      .getSpecificsRubricsOfFunction(functionId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Retourne la liste des rubriques globales associées à la fonction
   * triée dans l'ordre alphabétique
   */
   async getGlobalRubricsForFunction(functionId) {
    return this.api
      .getGlobalRubricsOfFunction(functionId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  /**
   * Ajouter une fonction
   */
  async create(func) {
    return this.api.create(func).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(func.name);
      converter.convert(error);
    });
  }

  /**
   * Ajouter une rubrique spécifique à une fonction
   */
  async addSpecificRubricToFunction(idFunction, idRubric, entity) {
    return this.api.addSpecificRubricToFunction(idFunction, idRubric, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   * Ajouter la spécialisation d'une rubrique globale à une fonction
   */
  async addGlobalRubricToFunction(idFunction, idRubric, entity) {
    return this.api.addGlobalRubricToFunction(idFunction, idRubric, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  /**
   * Mise à jour de la fonction
   */
  async update(func) {
    return this.api.update(func).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

   /**
   * Mettre à jour une rubrique spécifique à une fonction
   */
    async updateSpecificRubricToFunction(idFunction, idRubric, entity) {
      return this.api.updateSpecificRubricToFunction(idFunction, idRubric, entity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
    }

    /**
   * Mettre à jour la spécialisation d'une rubrique globale à une fonction
   */
  async updateGlobalRubricToFunction(idFunction, idRubric, entity) {
    return this.api.updateGlobalRubricToFunction(idFunction, idRubric, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  /**
   * Supprimer la fonction
   */
  async deleteFunction(functionId) {
    return this.api.delete(functionId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(functionId);
      converter.convert(error);
    });
  }

  /**
   * Supprimer une rubrique spécifique à une fonction
   */
  async deleteSpecificRubricToFunction(idFunction, idRubric) {
    return this.api
      .deleteSpecificRubricToFunction(idFunction, idRubric)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Supprimer la spécialisation d'une rubrique globale à une fonction
   */
  async deleteGlobalRubricToFunction(idFunction, idRubric) {
    return this.api
      .deleteGlobalRubricToFunction(idFunction, idRubric)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }
} // END OF CLASS
