<template>
  <!-- l'affichage de la table -->
  <v-card flat outlined class="my-4">
    <v-card-title class="font-weight-regular">
      <v-row no-gutters justify="space-between">
        <div>Détail de l'établissement</div>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="start">
          <div class="capitalize font-weight-regular">nom digital</div>
        </v-col>
        <v-col align-self="start">
          <div>{{ digitalName }}</div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="start">
          <div class="capitalize font-weight-regular">métier</div>
        </v-col>
        <v-col align-self="start">
          <div>{{ metier }}</div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="start">
          <div class="capitalize font-weight-regular">activités</div>
        </v-col>
        <v-col align-self="start" v-if="activities.length > 0">
          <div
            v-for="(activity, index) in activities"
            :key="'A' + index"
            class="text-uppercase"
          >
            {{ activity.label }}
          </div>
        </v-col>
        <v-col align-self="start" v-else>
          <div>-</div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="start">
          <div class="capitalize font-weight-regular">statut</div>
        </v-col>
        <v-col align-self="start">
          <div>{{ status }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EstablishmentPanel.vue",
  props: {
    /**le nom digital */
    digitalName: {
      type: String,
      default: "-",
    },

    /**le métier de l'établissement */
    metier: {
      type: String,
      default: "-",
    },

    /**les activités de l'établissement */
    activities: {
      type: Array,
      default: () => [],
    },

    /**le statut */
    status: {
      type: String,
      default: "-",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>