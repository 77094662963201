import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/sfr/metier_service.js";
const _M_GET_METIERS = "getMetiers";
const _M_CREATE = "create";
const _M_UPDATE = "update";
const _M_DELETE = "delete";

/**
 * Service d'accès à l'API Métiers
 */
export class MetierService {
  constructor(api) {
    this.api = api;
  }

  /////////////////////////////// GET /////////////////////////////////////////

  /**
   * Récupère la liste des métiers et les tri dans l'ordre alphabétique
   */
  async getAll() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_METIERS,
      "Récupération de la liste des métiers."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          let aname = a.name || "";
          let bname = b.name || "";
          return aname.localeCompare(bname);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère un métier par son identifiant
   */
  async getById(metierId) {
    return this.api.getById(metierId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(metierId);
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  /**
   * Création d'un métier
   */
  async create(metier) {
    logger.debugFull(_FILE_PATH, _M_CREATE, "Création du métier : ", metier);

    return this.api.create(metier).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(metier.id);
      converter.add409AlreadyExists(metier.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  /**
   * Mise à jour des données du métier
   */
  async update(metier) {
    logger.debugFull(
      _FILE_PATH,
      _M_UPDATE,
      "Mise à jour des infos du métier : ",
      metier
    );

    return this.api.update(metier).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(metier.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  /**
   * Suppression de du métier
   */
  async delete(idMetier) {
    logger.debugFull(
      _FILE_PATH,
      _M_DELETE,
      "Suppression du métier id : " + idMetier
    );

    return this.api.delete(idMetier).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idMetier);
      converter.convert(error);
    });
  }
} // END OF CLASS
