import * as statusMapper from "@/service/error_request_mapper.js";

export class UsersService {
  constructor(api) {
    this.api = api;
  }

  /** Récupère un utilisateur par son id */
  async getById(id) {
    return this.api.getById(id).catch((error) => {
      console.error("Error on service users" + error);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  // TODO: méthode à déprécier car se trouve dans le service **collaborator**
  // Chaque référence à ce service doit être remplacer par son pendant sur le service collaborator.
  /** Récupère un utilisateur par son uuid */
  async getByUuid(uuid) {
    return this.api.getByUuid(uuid).catch((error) => {
      console.error("Error on service users" + error.message);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère la liste des utilisateurs qui ont pour fonction principale ou secondaire la fonction dop */
  async getUsersFunctionDop(idFunctionDop) {
    return this.api.getUsersFunctionDop(idFunctionDop).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**recherche des utilisateurs par email */
  async searchDgUserByEmail(search, timestamp) {
    return this.api
      .searchUserByField("email_principal", search, 6)
      .then((result) => {
        return {
          users: result.users,
          timestamp,
        };
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }
  /**recherche des utilisateurs par email et retourne l'id user */
  async searchDgUserByEmailWithId(search, timestamp) {
    return this.api
      .searchUserByFieldWithId("email_principal", search, 1)
      .then((result) => {
        return {
          users: result.users,
          timestamp,
        };
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }


  /**Retourne la liste des utilisateurs ayant cette fonction digitale */
  async findUsersByFunctionId(functionId) {
    return this.api.findUsersByFunctionId(functionId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }
}
