import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/sfr/activity_service.js";
const _M_GET_ACTIVITIES = "getActivities";
const _M_GET_ACTIVITY_BY_ID = "getActivityById";
const _M_GET_SUBACTIVITIES_OF_ACTIVITY = "getSubActivitiesOfActivity";
const _M_UPDATE = "update";
const _M_CREATE = "createActivity";
const _M_DELETE = "deleteActivity";
const _M_ADD_SUBACTIVITIES_TO_ACTIVITY = "addSubActivityToActivity";
const _M_DELETE_SUBACTIVITIES_TO_ACTIVITY = "deleteSubActivityToActivity";

/** Service d'accès à l'API activity */
export class ActivityService {
  constructor(apis) {
    this.apis = apis;

    this.api = this.apis.getActivitiesApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////

  /**
   * Récupère la liste des activités et les classes par ordre alphabétique
   */
  async getActivities() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ACTIVITIES,
      "Récupération de la liste des activitées."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupère une activité par son id */
  async getActivityById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ACTIVITY_BY_ID,
      "Récupération d'une activité par son id'."
    );
    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   *  Récupération de la liste des sous-activitées associé à une activité
   *  puis tri les sous-activités récupérés dans l'ordre alphabétique
   */
  async getSubActivitiesOfActivity(idActivity) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_SUBACTIVITIES_OF_ACTIVITY,
      "Récupération de la liste des sous-activités associés à une activité."
    );

    return this.api
      .getSubactivitiesOfActivity(idActivity)
      .then((data) => {
        data.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération de l'ensemble des activités avec leurs sous-activités */
  async getActivitiesWithSubactivities() {
    return this.api.getActivitiesWithSubactivities()
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   * Récupération de la liste des dops associés à l'activité
   * @param {} activity
   */
  async getDopsOfActivity(idActivity) {
    return this.api.getDopsOfActivity(idActivity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  /**
   * Création d'une activité
   */
  async createActivity(activity) {
    logger.debugFull(
      _FILE_PATH,
      _M_CREATE,
      "Création de l'activité : ",
      activity
    );

    return this.api.create(activity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404();
      converter.add409AlreadyExists(activity.digitalName);
      converter.convert(error);
    });
  }

  /**
   * Ajout d'une sous-activité à l'activité spécifiée
   */
  async addSubActivityToActivity(idActivity, idSubActivity) {
    logger.debugFull(
      _FILE_PATH,
      _M_ADD_SUBACTIVITIES_TO_ACTIVITY,
      "Ajout d'une sous-activité id: " +
        idSubActivity +
        " à l'activité id : " +
        idActivity
    );

    return this.api
      .addSubActivityToActivity(idActivity, idSubActivity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(idActivity);
        converter.convert(error);
      });
  }

  /**
   * Ajout d'un dop à l'activité spécifiée
   */
  async addDopToActivity(idActivity, dop) {
    return this.api.addDopToActivity(idActivity, dop).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(dop.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  /**
   * Mise à jour des données l'activité
   */
  async updateActivity(activity) {
    logger.debugFull(
      _FILE_PATH,
      _M_UPDATE,
      "Mise à jour des infos de l'activité : " + activity.name
    );

    return this.api.update(activity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(activity.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  /**
   * Suppression d'une activité
   */
  async deleteActivity(idActivity) {
    logger.debugFull(
      _FILE_PATH,
      _M_DELETE,
      "Suppression de l'activité : " + idActivity
    );

    return this.api.delete(idActivity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idActivity);
      converter.convert(error);
    });
  }

  /**
   * Suppression d'une sous-activité à l'activité spécifiée
   */
  async deleteSubActivityToActivity(idActivity, idSubActivity) {
    logger.debugFull(
      _FILE_PATH,
      _M_DELETE_SUBACTIVITIES_TO_ACTIVITY,
      "Suppression d'une sous-activité id: " +
        idSubActivity +
        " à l'activité id : " +
        idActivity
    );

    return this.api
      .deleteSubActivityToActivity(idActivity, idSubActivity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(idActivity);
        converter.convert(error);
      });
  }

  /**
   * Suppression d'un dop à l'activité spécifiée
   */
  async deleteDopToActivity(idActivity, idDop) {
    return this.api.deleteDopToActivity(idActivity, idDop).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(idDop);
      converter.convert(error);
    });
  }
} // END OF CLASS
