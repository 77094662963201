import * as statusMapper from "@/service/error_request_mapper.js";

/**Le service de manipulation des référents fonctions par établissements. */
export default class ReferentFunctionService {
  constructor(api) {
    this.api = api;
  }

  /**Renvoie la liste des référents fonctions pour un établissement donné. Retourne un
   * tableau avec une entrée : une fonction + la liste des référents.  */
  async getReferentFunctionByEstablishment(establishmentId) {
    return this.api
      .getReferentFunctionByEstablishment(establishmentId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async addReferentToFunction(establishmentId, referentUuid, functionId) {
    let entity = {
      function: functionId,
      referentsUuid: [referentUuid],
    };
    // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
    // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
    delete entity.referents;

    return this.api.createEntity(establishmentId, entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**
   *Ajoute un référent sur une fonction digitale existante pour l'établissement.
   * @param {*} establishmentId identifiant de l'établissement
   * @param {*} entity l'entité relation fonction->référents
   * @param {*} referentUuid l'identifiant du référent à ajouter à la fonction
   * @returns
   */
  async addReferentToExistingFunction(establishmentId, entity, referentUuid) {
    let data = { ...entity };
    data.referentsUuid.push(referentUuid);

    // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
    // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
    delete data.referents;

    return this.api
      .updateEntity(establishmentId, data)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Supprimer un référent d'une fonction digitale existante
   * @param {*} establishmentId identifiant de l'établissement
   * @param {*} entity l'entité relation fonction->référents
   * @param {*} referentUuid l'identifiant du référent à ajouter à la fonction
   */
  async removeReferent(establishmentId, entity, referentUuid) {
    let data = { ...entity };
    
    // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
    // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
    delete data.referents;

    let index = data.referentsUuid.findIndex((r) => r == referentUuid);
    data.referentsUuid.splice(index, 1);

    if (data.referentsUuid.length > 0) {
      return this.api.updateEntity(establishmentId, data).catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
    } else {
      return this.api.deleteEntity(establishmentId, data).catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
    }
  }

  async updateReferent(
    establishmentId,
    entities,
    initialFunctionId,
    initialReferentUuid,
    functionId,
    referentUuid
  ) {

    if (initialFunctionId == functionId) {

      let entity = entities.find((e) => e.function === functionId);

      let data = { ...entity };

      // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
      // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
      delete data.referents;

      //on supprime le pécédent
      let index = data.referentsUuid.findIndex((r) => r == initialReferentUuid);
      data.referentsUuid.splice(index, 1);

      //on ajoute le nouveau
      await this.addReferentToExistingFunction(
        establishmentId,
        data,
        referentUuid
      );
    } else {
      // on ajoute le nouveau
      let entity = entities.find((e) => e.function === functionId);
      if (entity) {
        let data = { ...entity };
        // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
        // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
        delete data.referents;

        await this.addReferentToExistingFunction(
          establishmentId,
          data,
          referentUuid
        );
      } else {
        await this.addReferentToFunction(
          establishmentId,
          referentUuid,
          functionId
        );
      }

      entity = entities.find((e) => e.function === initialFunctionId);

      let data = { ...entity };
      // Ce champ n'est plus accepté par la route Put de mise à jour d'un référent fonction
      // de ce fait nous devons le supprimer car il est à ce jour présent dans l'objet récupéré lors du load.
      delete data.referents;
      await this.removeReferent(establishmentId, data, referentUuid);
    }
  }
}
